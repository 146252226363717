import { lazy, useCallback, useState } from "react";
import { toast } from "react-toastify";

import { useAssetQuery } from "@api/asset/hooks";
import useDeleteAsset from "@api/asset/hooks/useDeleteAsset";
import { usePolygonQuery } from "@api/polygon/hooks";
import { useUserQuery } from "@api/user/hooks";

import { ModalTypes, Sidebars } from "@constants/Modals";
import lazyWithPreload from "@shared/utils/lazyWithPreload";

import { useCesiumApp } from "./context/CesiumProvider";

const LandSketcher = lazyWithPreload(() =>
  import("@features/Land/LandSketcher")
);
const EntityInfoModal = lazy(() => import("@components/EntityInfoModal"));
const AssetForm = lazy(() => import("@features/Asset/AssetForm"));
const EditLand = lazy(() => import("@features/Land/EditLand"));
const TradeSidebar = lazy(() => import("@features/Trade/TradeSidebar"));
const AssetAdjustmentModal = lazy(() =>
  import("@features/Asset/AssetAdjustmentModal")
);

const CesiumApp = () => {
  const [openModal, setOpenModal] = useState(ModalTypes.NONE);
  const [openSidebar, setOpenSidebar] = useState(Sidebars.NONE);

  const { user } = useUserQuery();

  const { viewer, disableCesiumListeners } = useCesiumApp();

  const { deleteAssetFromPolygon } = useDeleteAsset(user, viewer);

  const { polygon, onPolygonPick } = usePolygonQuery({
    viewer,
    loadHandler: useCallback(
      (polygonOwnerId) => {
        if (polygonOwnerId === user?.id) {
          setOpenSidebar(Sidebars.TRADE_LAND_SIDEBAR);
          return;
        }
        setOpenModal(ModalTypes.LAND_INFO);
      },
      [user?.id]
    ),
    enabled:
      !disableCesiumListeners &&
      openModal === ModalTypes.NONE &&
      openSidebar === Sidebars.NONE,
  });
  const { asset, onAssetPick } = useAssetQuery({
    viewer,
    loadHandler: useCallback(
      (assetOwnerId) => {
        if (assetOwnerId === user?.id) {
          setOpenSidebar(Sidebars.TRADE_ASSET_SIDEBAR);
          return;
        }

        setOpenModal(ModalTypes.ASSET_INFO);
      },
      [user?.id]
    ),
    enabled:
      !disableCesiumListeners &&
      openModal === ModalTypes.NONE &&
      openSidebar === Sidebars.NONE,
  });

  const openModalHandler = (type) => {
    const isOwner =
      asset?.ownerId === user?.id || polygon?.ownerId === user?.id;
    if (isOwner) {
      setOpenModal(type);
      setOpenSidebar(Sidebars.NONE);
    }
  };

  const openSidebarHandler = (type) => {
    const isOwner =
      asset?.ownerId === user?.id || polygon?.ownerId === user?.id;
    if (isOwner) {
      setOpenSidebar(type);
      setOpenModal(ModalTypes.NONE);
    }
  };

  const handleDeleteAsset = async (assetId) => {
    setOpenSidebar(Sidebars.NONE);
    onAssetPick(null);
    try {
      await deleteAssetFromPolygon(assetId);
      toast.success("Asset successfully removed from the land");
    } catch (error) {
      toast.error("Something went wrong while removing the asset.");
    }
  };

  const onClose = () => {
    setOpenModal(ModalTypes.NONE);
    setOpenSidebar(Sidebars.NONE);
    onPolygonPick(null);
    onAssetPick(null);
  };

  return (
    <>
      <LandSketcher />
      {polygon && openModal === ModalTypes.LAND_INFO && (
        <EntityInfoModal
          entity={polygon}
          onClose={() => onClose("modal")}
          openSidebar={() => {
            setOpenSidebar(Sidebars.TRADE_LAND_SIDEBAR);
            setOpenModal(ModalTypes.NONE);
          }}
          isOwner={user?.id === polygon?.ownerId}
          entityType="polygon"
        />
      )}
      {polygon && openSidebar === Sidebars.TRADE_LAND_SIDEBAR && (
        <TradeSidebar
          user={user}
          entity={polygon}
          onClose={() => onClose("sidebar")}
          openModal={openModalHandler}
          openSidebar={openSidebarHandler}
          handleDeleteAsset={handleDeleteAsset}
          isOwner={user?.id === polygon?.ownerId}
          entityType="polygon"
          handleAssetPosition={(assetId) => {
            onAssetPick(assetId);
            setOpenModal(ModalTypes.ASSET_ADJUSTMENT);
            setOpenSidebar(Sidebars.NONE);
          }}
          handlePickAsset={(assetId) => {
            onAssetPick(assetId);
            setOpenSidebar(Sidebars.TRADE_ASSET_SIDEBAR);
            setOpenModal(ModalTypes.NONE);
          }}
        />
      )}

      {polygon && openSidebar === Sidebars.EDIT_LAND_SIDEBAR && (
        <EditLand
          user={user}
          polygon={polygon}
          onClose={() => onClose("sidebar")}
        />
      )}

      {asset && openModal === ModalTypes.ASSET_INFO && (
        <EntityInfoModal
          entity={asset}
          openSidebar={() => {
            setOpenSidebar(Sidebars.TRADE_ASSET_SIDEBAR);
            setOpenModal(ModalTypes.NONE);
          }}
          onClose={() => onClose("modal")}
          isOwner={user?.id === asset?.ownerId}
          entityType="asset"
        />
      )}
      {asset && openSidebar === Sidebars.TRADE_ASSET_SIDEBAR && (
        <TradeSidebar
          user={user}
          entity={asset}
          onClose={() => onClose("sidebar")}
          openModal={openModalHandler}
          openSidebar={openSidebarHandler}
          handleDeleteAsset={handleDeleteAsset}
          handleAssetPosition={(assetId) => {
            onAssetPick(assetId);
            setOpenModal(ModalTypes.ASSET_ADJUSTMENT);
            setOpenSidebar(Sidebars.NONE);
          }}
          isOwner={user?.id === asset.ownerId}
          entityType="asset"
        />
      )}
      {polygon && user && openModal === ModalTypes.CREATE_ASSET && (
        <AssetForm
          user={user}
          polygon={polygon}
          isLandOwner={user.id === polygon.ownerId}
          onClose={() => onClose("modal")}
        />
      )}
      {asset && user && openModal === ModalTypes.EDIT_ASSET && (
        <AssetForm
          user={user}
          asset={asset}
          isLandOwner={user.id === asset?.ownerId}
          onClose={() => onClose("modal")}
        />
      )}
      {asset && openModal === ModalTypes.ASSET_ADJUSTMENT && (
        <AssetAdjustmentModal asset={asset} onClose={() => onClose("modal")} />
      )}
    </>
  );
};

export default CesiumApp;
