import fetchData from "@api/axios";

const fileService = {
  async getPresignedUrl({ fileName, fileType, category }) {
    return await fetchData({
      routeKey: "getPresignedUrl",
      method: "POST",
      data: { fileName, fileType, category },
    });
  },
  async deleteFiles({ fileUrls }) {
    return await fetchData({
      routeKey: "deleteUploadedFiles",
      method: "POST",
      data: { fileUrls },
    });
  },
};

export { fileService };
