import { useQuery } from "@tanstack/react-query";

import { polygonService } from "@api/services";

export const useLocationTypeShares = ({ enabled }) =>
  useQuery(
    ["location-type-shares"],
    () => polygonService.getLocationTypeShares(),
    {
      enabled,
    }
  );
