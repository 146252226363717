import fetchData from "@api/axios";

const polygonService = {
  async getPolygonLocation(latitude, longitude) {
    return await fetchData({
      routeKey: "getPolygonLocation",
      method: "POST",
      data: { latitude, longitude },
    });
  },
  async getLocationTypeShares() {
    return await fetchData({
      routeKey: "getLocationTypeShares",
    });
  },
  async getPolygons() {
    return await fetchData({
      routeKey: "getPolygons",
    });
  },
  async getPolygon(identifier) {
    return await fetchData({
      routeKey: "getPolygon",
      params: identifier,
    });
  },
  async createPolygon(data) {
    return await fetchData({
      routeKey: "createPolygon",
      method: "POST",
      data,
    });
  },
  async updatePolygon({ identifier, data }) {
    return await fetchData({
      routeKey: "updatePolygon",
      method: "PUT",
      params: identifier,
      data,
    });
  },
  async getLocationTypes() {
    return await fetchData({
      routeKey: "getLocationTypes",
    });
  },
};

export { polygonService };
