import {
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined as cesiumDefined,
} from "cesium";
import { useEffect, useMemo, useState } from "react";

import { useQueryData } from "@api/hooks";
import { polygonService } from "@api/services";

const usePolygonQuery = ({ viewer, loadHandler, enabled }) => {
  const [polygonId, setPolygonId] = useState(null);

  const { data } = useQueryData(
    ["polygons", polygonId],
    async () => {
      return await polygonService.getPolygon(polygonId);
    },
    {
      enabled: !!polygonId,
    }
  );

  const onPolygonPick = (id) => {
    setPolygonId(id);
  };

  useEffect(() => {
    if (!viewer?.canvas || !viewer?.scene || !enabled) {
      return;
    }

    const handler = new ScreenSpaceEventHandler(viewer.canvas);

    handler.setInputAction((event) => {
      const pickedObject = viewer.scene.pick(event.position);

      if (
        cesiumDefined(pickedObject) &&
        cesiumDefined(pickedObject.id) &&
        pickedObject.id.polygon
      ) {
        const entityId = pickedObject.id.id;
        const polygonOwnerId = pickedObject.id.properties?.ownerId?.getValue();
        const [type, ...rest] = entityId.split("-");
        const id = rest.join("-");
        if (type === "polygon") {
          setPolygonId(id);
          loadHandler(polygonOwnerId);
        }
      }
    }, ScreenSpaceEventType.LEFT_CLICK);

    return () => {
      handler.destroy();
    };
  }, [enabled, loadHandler, viewer?.canvas, viewer?.scene]);

  return useMemo(
    () => ({
      polygon: data,
      polygonId,
      onPolygonPick,
    }),
    [data, polygonId]
  );
};

export { usePolygonQuery };
